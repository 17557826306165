<template>
  <div class="container">
    <b-loading :is-full-page="true" v-model="isLoading"></b-loading>
    <img src="../assets/Story-Icon-Hero.svg" alt="Centivo" class="logo" />
    <ForgotPassword @forgotPassword="isLoading = $event"/>
  </div>
</template>

<script>
import ForgotPassword from '../components/forgot-password.vue'

export default {
  name: 'forgotPassword',
  components: {
    ForgotPassword
  },
  data () {
    return {
      isLoading: false
    }
  },
  methods: {

  }
}
</script>

<style lang="scss">
.forgotPassword {
  width: 25%;
  align-content: center;
  align-self: center;
  display: inline-block;
}

.logo {
  display: block;
  margin: auto;
  width: 48px;
  padding-top: 10%;
  margin-bottom: 32px;
}
</style>
