<template>
  <div class="forgotPassword">
    <section>
      <h1 class="subtitle">Please enter your username to get the confirmation code.</h1>
      <b-field label="Username">
        <div class="send-code-area">
          <b-input v-model="username" ></b-input>
        </div>
      </b-field>
      <b-button @click="forgotPassword" size="is-small" class="mb-5" :disabled="!this.username" expanded>
        Send Confirmation Code
      </b-button>

      <h1 class="subtitle">After receiving the confirmation code, insert it and the new password in the fields below.</h1>

      <b-field label="Confirmation Code">
        <b-input type="number" v-model="confirmationCode" >
        </b-input>
      </b-field>

      <b-field
        label="New Password"
        :type="formDisplay.password.type"
        :message="formDisplay.password.message"
      >
        <b-input @input="verifyPasswordRequirements" placeholder="New Password" type="password" v-model="password" password-reveal>
        </b-input>
      </b-field>

      <div class="button-area">
        <b-field>
          <b-button @click="backToLogin" type="is-warning">
            Back
          </b-button>
        </b-field>
        <b-field>
          <b-button @click="confirmPassword" type="is-success" :disabled="!this.confirmationCode || !this.password || this.formDisplay.password.type === 'is-danger'">
            Reset Password
          </b-button>
        </b-field>
      </div>
    </section>
  </div>
</template>

<script>
export default {
  name: 'forgot-password',
  data () {
    // TODO: Remove these defaults
    return {
      username: '',
      confirmationCode: '',
      password: '',
      formDisplay: {
        password: { type: '', message: '' }
      }
    }
  },
  methods: {
    verifyPasswordRequirements () {
      const requirements = /^(?=.*\d)(?=.*[A-Z])(?=.*[\W_]).{8,}$/g

      if (!this.password.match(requirements)) {
        this.formDisplay.password.type = 'is-danger'
        this.formDisplay.password.message = 'Password must contain a minimum of 8 characters, one uppercase letter, one special character and one number'
      } else {
        this.formDisplay.password.type = 'is-success'
        this.formDisplay.password.message = ''
      }
    },
    async forgotPassword () {
      this.$emit('forgotPassword', true)
      this.$store.dispatch('auth/forgotPassword', { username: this.username })
        .then((res) => {
          this.$emit('forgotPassword', false)
          this.$buefy.toast.open({
            message: 'Confirmation sent! Check your inbox',
            type: 'is-success',
            duration: 5000,
            position: 'is-top'
          })
        })
        .catch((err) => {
          console.error(err)
          this.$emit('forgotPassword', false)
          this.$buefy.toast.open({
            message: 'It was not possible to send the confirmation code to the given username',
            type: 'is-danger',
            duration: 5000,
            position: 'is-top'
          })
          this.$emit('forgotPassword', false)
        })
    },
    async confirmPassword () {
      this.$emit('forgotPassword', true)
      this.$store.dispatch('auth/confirmPassword', { username: this.username, confirmationCode: this.confirmationCode, password: this.password })
        .then((res) => {
          this.$emit('forgotPassword', false)
          this.$buefy.toast.open({
            message: 'Account verified! Please, login with your new password',
            type: 'is-success',
            duration: 5000,
            position: 'is-top'
          })
          this.$router.push({ path: '/login' })
        })
        .catch((err) => {
          console.error(err)
          this.$emit('forgotPassword', false)
          this.$buefy.toast.open({
            message: 'It was not possible to reset the password with the given confirmation Code',
            type: 'is-danger',
            duration: 5000,
            position: 'is-top'
          })
          this.$emit('forgotPassword', false)
        })
    },
    backToLogin () {
      this.$router.push({ path: '/login' })
    }
  },
  computed: {

  },
  mounted () {
    loadData()
  }
}
function loadData () {
  return {}
}
</script>

<style lang="scss" scoped>
  h3 {
      font-size: large;
      padding-top: 100px;
      text-align: center;
  }
  .send-code-area {
    display: flex;
    flex-direction: column;
    button {
      margin-top: 10px;
    }
  }
  .button-area {
    display: flex;
    justify-content: space-between;
  }
</style>
